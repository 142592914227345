import { useEffect } from 'react';
import { type ScriptDescriptor } from 'remix-utils/external-scripts';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';
import { gtagEnsure } from './gtag';

export function gaScripts(): ScriptDescriptor[] {
  const config = marketing(getEnv());
  return config.registrationMarketingTrackingEnabled
    ? [
        {
          src: 'https://www.googletagmanager.com/gtag/js?id=G-4JG0D8YBE8',
          async: true,
        },
      ]
    : [];
}

function gaIdentify() {
  gtagEnsure();
  gtag('js', new Date());
  gtag('config', 'G-4JG0D8YBE8');
}

export function GAIdentify() {
  useEffect(() => {
    gaIdentify();
  }, []);
  return null;
}
